'use client';
import Button from '@src/components/atoms/buttons/button';
import { banner } from '@src/constants';
import type { RootState } from '@src/store/store';
import { useRouter } from 'next/navigation';
import { useSelector } from 'react-redux';
import SparkIcon from '@src/components/icons/sparkIcon';
import GreenCheck from '@src/components/icons/GreenCheck';
import { useModal } from '../modal-views/context';

export default function Banner() {
  const wallet = useSelector((state: RootState) => state.wallet);
  const router = useRouter();
  const { openModal } = useModal();

  const onSignIn = () => {
    openModal('CONNECT_WALLET');
  };

  const handleButton = () => {
    if (wallet.instance !== null) {
      router.push('/drep/mint');
    } else {
      onSignIn();
    }
  };

  return (
    <div className="relative flex w-full flex-col items-center justify-center overflow-clip bg-opacity-50 bg-banner-background-pattern max-md:px-1">
      <div className="z-20 my-24 flex w-full max-w-[713px] flex-col items-center justify-center gap-2 sm:my-32 sm:gap-5">
        <div className="flex items-center justify-center gap-[3px] rounded-[32px] border-[1px] border-dark-neutral-100 bg-dark-neutral-700 px-3 py-1 shadow-introducing-shadow backdrop-blur-[6px]">
          <SparkIcon className="text-[#FFFFFF80]" />
          <span className="body14 font-medium">Introducting to DRepID</span>
        </div>
        <div className="relative flex w-full flex-col items-center justify-center gap-y-5">
          <div className="bg-banner-text-box-pruple-gradient relative">
            <div className="text-center">
              <div className="absolute -z-10 flex h-[300px] w-full items-center justify-center bg-banner-text-box-purple-gradient opacity-100">
                <div className="h-[1px] w-[448px] bg-banner-text-box-purple-line" />
              </div>
              <span className="h56-responsive font-bold sm:!leading-[70px]">
                {banner.title}
              </span>
            </div>
          </div>
          <p className="body1 text-center">{banner.description}</p>
          <div className="flex gap-x-5 max-md:gap-x-3">
            {banner.features.map((feature) => {
              return (
                <div key={feature} className="flex items-center gap-x-1">
                  <GreenCheck />
                  <p className="body16-responsive font-medium">{feature}</p>
                </div>
              );
            })}
          </div>
          <Button
            onClick={handleButton}
            buttonSize="large"
            className="inner-shadow-white inset shadow-Inner-B-Shadow-6 shadow-white"
          >
            Create your DRep ID
          </Button>
        </div>
      </div>
      <div className="absolute bottom-0 z-10 h-[1052px] w-full min-w-[1440px] bg-banner-ellipse" />
      <div className="absolute top-0 z-[5] h-full w-full bg-gradient-to-b from-black via-black to-transparent" />
      <div className="absolute z-[5] h-full w-full bg-profile-illustration-left bg-contain bg-no-repeat shadow-Profile-Illustration-left-shadow max-md:opacity-50 max-md:[background-position:10%_80%]" />
      <div className="absolute right-0 z-[5] h-full w-full bg-profile-illustration-right bg-contain bg-right-bottom bg-no-repeat shadow-Profile-Illustration-right-shadow max-md:w-1/2 max-md:opacity-50" />
    </div>
  );
}
