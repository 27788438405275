import { useRouter } from 'next/navigation';

export default function LandingPageDrepCard({
  name,
  dRepHandle,
  votingPower,
  profilePhoto,
  coverPhoto,
}: {
  name: string;
  dRepHandle: string;
  votingPower: number | string;
  profilePhoto: string;
  coverPhoto: string;
}) {
  const router = useRouter();
  return (
    <div
      onClick={() => router.push(`/${dRepHandle}`)}
      className="cursor-pointer overflow-clip border-[1px] border-l-[0.5px] border-r-[0.5px] border-[#282B30] p-2"
    >
      <div className="w-[268px]">
        <div className="relative">
          <div
            className="relative h-[90px] w-full grayscale"
            style={{
              backgroundImage: `url(${coverPhoto})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <div className="absolute bottom-[-30px] left-1/2 translate-x-[-50%]">
            <div
              className={`h-[64.8px] w-[64.8px] rounded-full`}
              style={{
                backgroundImage: `url(${profilePhoto})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
          </div>
          <div />
        </div>
        <div className="px-9 pb-6 pt-12 text-center">
          <p className="body16 max-w-64 truncate text-ellipsis font-semibold">
            {name}
          </p>
          <p className="max-w-64 truncate text-ellipsis text-gray-400">
            @{dRepHandle}
          </p>
          <p className="label12 font-medium text-gray-400">
            Voting Power : &nbsp;
            <span className="body20 font-bold text-white">₳ {votingPower}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
