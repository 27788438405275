import React from 'react';

export default function SparkIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="mix-blend-mode:screen"
        d="M8.00016 5.41671C8.00016 5.27288 7.88357 5.15629 7.73975 5.15629C6.8768 5.15629 6.17725 4.45674 6.17725 3.59379C6.17725 3.44997 6.06065 3.33337 5.91683 3.33337C5.773 3.33337 5.65641 3.44997 5.65641 3.59379C5.65641 4.45674 4.95686 5.15629 4.09391 5.15629C3.95009 5.15629 3.8335 5.27288 3.8335 5.41671C3.8335 5.56053 3.95009 5.67712 4.09391 5.67712C4.95686 5.67712 5.65641 6.37667 5.65641 7.23962C5.65641 7.38345 5.773 7.50004 5.91683 7.50004C6.06065 7.50004 6.17725 7.38345 6.17725 7.23962C6.17725 6.37667 6.8768 5.67712 7.73975 5.67712C7.88357 5.67712 8.00016 5.56053 8.00016 5.41671Z"
        fill="url(#paint0_linear_4249_11749)"
      />
      <path
        d="M8.00016 5.41671C8.00016 5.27288 7.88357 5.15629 7.73975 5.15629C6.8768 5.15629 6.17725 4.45674 6.17725 3.59379C6.17725 3.44997 6.06065 3.33337 5.91683 3.33337C5.773 3.33337 5.65641 3.44997 5.65641 3.59379C5.65641 4.45674 4.95686 5.15629 4.09391 5.15629C3.95009 5.15629 3.8335 5.27288 3.8335 5.41671C3.8335 5.56053 3.95009 5.67712 4.09391 5.67712C4.95686 5.67712 5.65641 6.37667 5.65641 7.23962C5.65641 7.38345 5.773 7.50004 5.91683 7.50004C6.06065 7.50004 6.17725 7.38345 6.17725 7.23962C6.17725 6.37667 6.8768 5.67712 7.73975 5.67712C7.88357 5.67712 8.00016 5.56053 8.00016 5.41671Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        className="mix-blend-mode:screen"
        d="M14.25 5.00004C14.25 4.76992 14.0634 4.58337 13.8333 4.58337C13.6033 4.58337 13.4167 4.76992 13.4167 5.00004C13.4167 7.07077 11.7377 8.75004 9.66667 8.75004C9.43658 8.75004 9.25 8.93662 9.25 9.16671C9.25 9.39679 9.43658 9.58337 9.66667 9.58337C11.7378 9.58337 13.4167 11.2619 13.4167 13.3334C13.4167 13.5635 13.6033 13.75 13.8333 13.75C14.0634 13.75 14.25 13.5635 14.25 13.3334C14.25 11.2618 15.9284 9.58337 18 9.58337C18.2301 9.58337 18.4167 9.39679 18.4167 9.16671C18.4167 8.93662 18.2301 8.75004 18 8.75004C15.9285 8.75004 14.25 7.07081 14.25 5.00004Z"
        fill="url(#paint1_linear_4249_11749)"
      />
      <path
        d="M14.25 5.00004C14.25 4.76992 14.0634 4.58337 13.8333 4.58337C13.6033 4.58337 13.4167 4.76992 13.4167 5.00004C13.4167 7.07077 11.7377 8.75004 9.66667 8.75004C9.43658 8.75004 9.25 8.93662 9.25 9.16671C9.25 9.39679 9.43658 9.58337 9.66667 9.58337C11.7378 9.58337 13.4167 11.2619 13.4167 13.3334C13.4167 13.5635 13.6033 13.75 13.8333 13.75C14.0634 13.75 14.25 13.5635 14.25 13.3334C14.25 11.2618 15.9284 9.58337 18 9.58337C18.2301 9.58337 18.4167 9.39679 18.4167 9.16671C18.4167 8.93662 18.2301 8.75004 18 8.75004C15.9285 8.75004 14.25 7.07081 14.25 5.00004Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        className="mix-blend-mode:screen"
        d="M6.75 10.4167C6.75 10.1866 6.56345 10 6.33333 10C6.10322 10 5.91667 10.1866 5.91667 10.4167C5.91667 11.7974 4.79738 12.9167 3.41667 12.9167C3.18655 12.9167 3 13.1033 3 13.3333C3 13.5634 3.18655 13.75 3.41667 13.75C4.79738 13.75 5.91667 14.8692 5.91667 16.25C5.91667 16.4801 6.10322 16.6667 6.33333 16.6667C6.56345 16.6667 6.75 16.4801 6.75 16.25C6.75 14.8692 7.86928 13.75 9.25 13.75C9.48008 13.75 9.66667 13.5634 9.66667 13.3333C9.66667 13.1033 9.48008 12.9167 9.25 12.9167C7.86928 12.9167 6.75 11.7974 6.75 10.4167Z"
        fill="url(#paint2_linear_4249_11749)"
      />
      <path
        d="M6.75 10.4167C6.75 10.1866 6.56345 10 6.33333 10C6.10322 10 5.91667 10.1866 5.91667 10.4167C5.91667 11.7974 4.79738 12.9167 3.41667 12.9167C3.18655 12.9167 3 13.1033 3 13.3333C3 13.5634 3.18655 13.75 3.41667 13.75C4.79738 13.75 5.91667 14.8692 5.91667 16.25C5.91667 16.4801 6.10322 16.6667 6.33333 16.6667C6.56345 16.6667 6.75 16.4801 6.75 16.25C6.75 14.8692 7.86928 13.75 9.25 13.75C9.48008 13.75 9.66667 13.5634 9.66667 13.3333C9.66667 13.1033 9.48008 12.9167 9.25 12.9167C7.86928 12.9167 6.75 11.7974 6.75 10.4167Z"
        fill="white"
        fillOpacity="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4249_11749"
          x1="3.00016"
          y1="16.6667"
          x2="18.4168"
          y2="16.6688"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E59CFF" />
          <stop offset="0.5" stopColor="#BA9CFF" />
          <stop offset="1" stopColor="#9CB2FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4249_11749"
          x1="3"
          y1="16.6667"
          x2="18.4167"
          y2="16.6688"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E59CFF" />
          <stop offset="0.5" stopColor="#BA9CFF" />
          <stop offset="1" stopColor="#9CB2FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4249_11749"
          x1="3"
          y1="16.6667"
          x2="18.4167"
          y2="16.6688"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E59CFF" />
          <stop offset="0.5" stopColor="#BA9CFF" />
          <stop offset="1" stopColor="#9CB2FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
