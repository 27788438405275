import PlusIcon from '../icons/plus';
import Pointer3d from '../icons/Pointer3d';
import QuestionMarkIcon from '../icons/questionMarkIcon';
import { faq } from '@src/constants';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { useScrollContext } from '@src/context/scroll-context';

export default function FAQ() {
  const { faqRef } = useScrollContext();
  return (
    <div
      className="relative flex w-full flex-col items-center justify-center gap-12 overflow-clip py-24"
      ref={faqRef}
    >
      <div className="fixed top-0 h-[220px] w-full translate-y-[-50%] bg-section-divider" />
      <div className="fixed bottom-0 -z-10 flex h-[220px] w-full translate-y-[50%] items-center justify-center bg-section-divider-light">
        <div className="h-[1px] w-full bg-layout-divider" />
      </div>
      <Pointer3d className="absolute right-1/4 top-[200px] max-sm:right-2 max-sm:top-[120px]" />
      <Pointer3d className="absolute -left-9 top-[500px] rotate-90 max-sm:top-[460px]" />
      <div className="flex flex-col items-center gap-4">
        <div className="flex items-center gap-2">
          <QuestionMarkIcon color="#6106FF" />
          <p className="purple-gradient-text tiny-text font-bold">
            {faq.title}
          </p>
        </div>
        <p className="h40 font-bold max-sm:text-[24px] max-sm:leading-[36px] max-sm:tracking-[-0.47px]">
          Frequently Asked Questions
        </p>
        <p className="body16 text-center font-medium text-gray-400">
          Find questions and answers related to the
          <span className="font-bold"> DrepID </span>, <br></br> purchase,
          updates, and support.
        </p>
      </div>
      <div className="grid grid-cols-1 gap-y-3 max-sm:px-5">
        {faq.questions.map((item) => (
          <Disclosure key={item.question}>
            <DisclosureButton className={'group'}>
              <div className="flex w-[564px] items-center justify-between rounded-xl border-[1px] border-[#FFFFFF14] bg-faq-question-box p-4 max-sm:w-full">
                <span className="body16 font-medium">{item.question}</span>
                <PlusIcon className="duration-500 group-data-[open]:rotate-180" />
              </div>
            </DisclosureButton>
            <DisclosurePanel
              className={
                'w-[564px] origin-top transition duration-500 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0 max-sm:w-full'
              }
              transition={true}
            >
              <div className="body14 rounded-lg bg-faq-question-box p-2 px-4 text-[14px]">
                {item.answer}
              </div>
            </DisclosurePanel>
          </Disclosure>
        ))}
      </div>
      <p className="body16">
        Contact us at &nbsp;
        <span className="font-semibold underline decoration-0">
          support@drep.id
        </span>{' '}
        via email!
      </p>
    </div>
  );
}
