'use client';

import { features } from '@src/constants';
import { useScrollContext } from '@src/context/scroll-context';
import Seperator from '../icons/Seperator';
import CubeIcon from '../icons/cubeIcon';
import GreenCheck from '../icons/GreenCheck';

export default function Features() {
  const { featuresRef } = useScrollContext();
  return (
    <div
      ref={featuresRef}
      className="flex w-full flex-col gap-y-24 overflow-clip py-[60px] max-2xl:px-2 max-md:px-5 sm:py-[120px]"
    >
      {features.map((feature) => (
        <div
          className="flex w-full flex-col justify-center gap-x-5 md:flex-row"
          key={feature.title}
        >
          <div className="flex flex-col sm:w-[406px]">
            <div>
              <div className="flex items-center gap-2">
                <CubeIcon color="#6106FF" />
                <p className="tiny-text purple-gradient-text font-bold uppercase">
                  Our Features
                </p>
                <Seperator color="white" />
                <p className="body14-responsive font-medium">
                  {feature.subTitle}
                </p>
              </div>
            </div>
            <p className="h36-responsive mt-1 font-bold">{feature.title}</p>
            <p className="body16 mt-5 font-medium">{feature.description}</p>
            <div className="mt-5 flex items-center gap-1">
              <GreenCheck />
              <p className="body16 font-medium">{feature.keySentence}</p>
            </div>
          </div>
          <div
            className="h-[616px] w-[720px] bg-right-top bg-no-repeat max-md:mt-8 max-md:h-[524px] max-md:w-[392px] max-md:bg-cover max-md:bg-center"
            style={{ backgroundImage: `url(${feature.image})` }}
          />
        </div>
      ))}
    </div>
  );
}
