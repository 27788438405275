'use client';
import { useScrollContext } from '@src/context/scroll-context';
import CubeIcon from '../icons/cubeIcon';
import DialogIconWrapper from '../atoms/dialogIconWrapper';
import Wallet from '../icons/wallet';
import Image from 'next/image';
import ShareIcon from '../icons/share';
import MenuIcon from '../icons/menu';
import CopyIcon from '../icons/copy';
import EditIcon from '../icons/edit';
import GreenCheck from '../icons/GreenCheck';
import LogoComponent from '../atoms/logoComponent';
import { howItWorks } from '@src/constants';

export default function HowItWorks() {
  const { howItWorksRef } = useScrollContext();
  return (
    <div ref={howItWorksRef} className="w-full p-3">
      <div className="relative flex w-full flex-col items-center rounded-6xl border-[1px] border-[#FFFFFF14] py-[96px]">
        <div className="absolute top-0 h-[220px] w-full translate-y-[-50%] bg-section-divider" />
        <div className="absolute bottom-0 z-10 h-[422px] w-full bg-how-it-works-bg bg-cover bg-center bg-no-repeat" />
        <div className="z-20 mb-[80px] flex w-[500px] flex-col items-center gap-y-2 max-xl:w-full max-xl:px-5">
          <div className="flex items-center gap-2">
            <CubeIcon className="text-[#6106FF]" />
            <p className="tiny-text purple-gradient-text font-bold">
              How it Works
            </p>
          </div>
          <p className="h40-responsive text-center font-bold">
            {howItWorks.title}
          </p>
          <p className="body16 text-center font-medium text-gray-400">
            {howItWorks.description}
          </p>
        </div>
        <div className="z-20 flex w-[1128px] flex-col gap-6 max-xl:w-full max-xl:px-3">
          <ConnectWalletSteps />
          <div className="flex w-full gap-6 max-xl:flex-col">
            <div className="relative w-full overflow-clip rounded-4xl bg-[#111316]">
              <div className="p-8 pb-0 max-xl:px-4">
                <p className="body20 font-semibold max-xl:text-center">
                  {howItWorks.steps[1].title}
                </p>
                <p className="body16 font-medium text-gray-400 max-xl:text-center">
                  {howItWorks.steps[1].description}
                </p>
              </div>
              <div className="rel mt-6 h-[445px] w-full overflow-clip bg-[url('/how-it-works-1.png')] bg-right-bottom bg-no-repeat max-xl:h-[294px] max-xl:bg-center max-sm:bg-left-top" />
              <div className="absolute bottom-0 h-1/3 w-full bg-gradient-to-b from-transparent via-black to-black opacity-50" />
            </div>
            <div className="relative w-full overflow-clip rounded-4xl bg-[#111316]">
              <div className="p-8 pb-0 max-xl:px-4">
                <p className="body20 font-semibold max-xl:text-center">
                  {howItWorks.steps[2].title}
                </p>
                <p className="body16 font-mediumm text-gray-400 max-xl:text-center">
                  {howItWorks.steps[2].description}
                </p>
              </div>
              <div className="flex justify-center max-xl:w-full">
                <div className="mt-6 h-[445px] w-full overflow-clip bg-[url('/how-it-works-2.png')] bg-center bg-no-repeat max-xl:h-[318px] max-xl:w-[282px] max-xl:bg-cover" />
              </div>
              <div className="absolute bottom-0 h-1/3 w-full bg-gradient-to-b from-transparent via-black to-black opacity-50" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ConnectWalletDialog = () => {
  return (
    <div className="flex w-[400px] flex-col items-center gap-5 rounded-2xl bg-dark-neutral-700 px-8 py-12 text-center max-xl:w-[303px] max-xl:px-4">
      <DialogIconWrapper>
        <Wallet />
      </DialogIconWrapper>
      <div className="gap-1 text-center">
        <p className="body20-responsive font-semibold">Connect your Wallet</p>
        <p className="body14 text-gray-400 max-xl:text-[10.63px] max-xl:leading-[15.19px] max-xl:tracking-[-0.07px]">
          Select the kind of wallet you want to connect.
        </p>
      </div>
      <div className="grid w-full grid-cols-2 gap-4">
        {['Lace', 'Eternl'].map((wallet) => (
          <div
            key={wallet}
            className="flex h-[130px] w-full flex-col items-center justify-center rounded-lg bg-dark-neutral-900 max-xl:h-[98px]"
          >
            <Image
              src={`/features/${wallet.toLowerCase()}.png`}
              alt={wallet}
              width={wallet === 'Lace' ? 47 : 41}
              height={wallet === 'Lace' ? 44 : 38}
              className={
                wallet === 'Lace'
                  ? 'max-xl:h-[33.42px] max-xl:w-[35.7px]'
                  : 'max-xl:h-[28.87px] max-xl:w-[31.4px]'
              }
            />
            <p className="body16 font-semibold max-xl:text-[12.15px] max-xl:leading-[18.23px] max-xl:tracking-[-0.14px]">
              {wallet}
            </p>
          </div>
        ))}
      </div>
      <div></div>
      <div className="flex items-center justify-center gap-x-2">
        <p className="body14 rounded-xl bg-white px-4 py-2 font-semibold text-black">
          Cancel
        </p>
        <p className="body14 rounded-xl bg-primary-800 px-4 py-2 font-semibold">
          Connect
        </p>
      </div>
    </div>
  );
};

const DRepProfile = () => {
  return (
    <div className="w-[288px] overflow-clip rounded-xl border-[1px] border-dark-neutral-700">
      <div className="ovef relative w-full">
        <div className="h-[100px] w-full bg-[url('/dReps/dRep-cover-1.png')] bg-cover bg-no-repeat" />
        <div className="absolute left-1/2 h-[86.4px] w-[86.4px] -translate-x-1/2 -translate-y-3/4 rounded-full bg-[url('/dReps/dRep-profile-high-reso.jpeg')] bg-cover bg-center" />
      </div>
      <div className="flex flex-col justify-center gap-y-4 py-8">
        <div className="text-center">
          <p className="body18 font-semibold">Eleanor Pena</p>
          <p className="label12 text-gray-400">@eleanor-drep1</p>
          <p className="label12 text-gray-400">
            Voting Power : &nbsp;{' '}
            <span className="h24 font-bold text-white"> ₳ 1836.98</span>
          </p>
        </div>
        <div className="flex items-center justify-center gap-1">
          <p className="body14 w-fit rounded-xl bg-white px-3 py-2 font-medium text-black">
            Delegate your Vote
          </p>
          <div className="rounded-xl bg-dark-neutral-700 px-[10px] py-2">
            <ShareIcon stroke="white" width={21} height={21} />
          </div>
          <div className="rounded-xl bg-dark-neutral-700 px-[10px] py-2">
            <MenuIcon stroke="white" width={21} height={21} />
          </div>
        </div>
        <div></div>
        <div className="flex justify-center gap-2">
          <div className="flex items-center gap-1 rounded-xl bg-dark-neutral-700 px-[10px] py-[6px]">
            <p className="label12 font-medium">drep1glrdxa4t66m9</p>
            <CopyIcon width={12} height={12} />
          </div>
          <div className="flex items-center gap-1 rounded-xl bg-dark-neutral-700 px-[10px] py-[6px]">
            <p className="label12 font-medium">Edit Profile</p>
            <EditIcon width={12} height={12} />
          </div>
        </div>
      </div>
      <div className="flex justify-between border-t-[1px] border-dark-neutral-700 bg-[#181A1D] px-[16px] py-[10px]">
        <div className="label12 flex items-center gap-1 font-semibold">
          <GreenCheck />
          <p>Registered</p>
        </div>
        <LogoComponent />
      </div>
    </div>
  );
};

const ConnectWalletSteps = () => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-y-12 rounded-4xl bg-dark-neutral-900 px-8 py-12 max-xl:px-5">
      <div className="w-[500px] gap-y-3 text-center max-xl:w-full">
        <p className="body20 font-semibold">{howItWorks.steps[0].title}</p>
        <p className="text-gray-400">{howItWorks.steps[0].description}</p>
      </div>
      <div className="flex w-full items-center justify-center max-xl:flex-col">
        <div className="rounded-2xl border-[1px] border-dark-neutral-700 p-2 max-xl:p-[6px]">
          <ConnectWalletDialog />
        </div>
        <div className="flex items-center justify-center max-xl:flex-col">
          <div className="w-10 border-[1.5px] border-dashed border-gray-600 max-xl:h-8 max-xl:w-[1px]" />
          <p className="body16 bg-status-success-400 rounded-[12px] bg-semantic-success-600 px-3 py-2 font-semibold">
            Connecting
          </p>
          <div className="w-10 border-[1.5px] border-dashed border-gray-600 max-xl:h-8 max-xl:w-[1px]" />
        </div>
        <div className="rounded-2xl border-[1px] border-dark-neutral-700 p-2">
          <DRepProfile />
        </div>
      </div>
    </div>
  );
};
