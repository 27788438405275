'use client';
import Banner from '@src/components/landing-sections/banner';
import Dreps from '@src/components/landing-sections/dreps';
import FAQ from '@src/components/landing-sections/faq';
import Features from '@src/components/landing-sections/features';
import GetStarted from '@src/components/landing-sections/getStarted';
import HowItWorks from '@src/components/landing-sections/howItWorks';
import withWalletEventListener from '@src/walletEvent/walletStateUtils';
import { useEffect, useState } from 'react';

function Home() {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    // This ensures that rendering only happens after the component is mounted on the client.
    // Prevent Hydration error
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return (
      <div className="flex min-h-calc-548 w-full items-center justify-center bg-white dark:bg-dark-neutral-950">
        <div className="h-14 w-14 animate-spin rounded-full border-b-2 border-primary-900 dark:border-white"></div>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <Banner />
      <Dreps />
      <Features />
      <HowItWorks />
      <FAQ />
      <GetStarted />
    </div>
  );
}
export default withWalletEventListener(Home);
