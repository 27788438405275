'use client';
import type { RootState } from '@src/store/store';
import { useRouter } from 'next/navigation';
import { useSelector } from 'react-redux';
import Button from '../atoms/buttons/button';
import { getStarted } from '@src/constants';
import { useModal } from '../modal-views/context';

export default function GetStarted() {
  const wallet = useSelector((state: RootState) => state.wallet);
  const router = useRouter();
  const { openModal } = useModal();

  const handleButton = () => {
    if (wallet.instance !== null) {
      router.push('/drep/mint');
    } else {
      openModal('CONNECT_WALLET');
    }
  };
  return (
    <div className="flex w-full justify-center pt-[72px] max-lg:px-5 lg:px-[156px] lg:pt-24">
      <div className="flex rounded-[28px] border-[1px] border-dark-neutral-600 p-2 max-lg:w-full">
        <div className="flex w-full flex-col items-center justify-center gap-y-2 rounded-[28px] bg-get-started-background bg-cover px-16 py-24 max-lg:px-6 max-lg:py-12 lg:w-[1112px]">
          <p className="h48 font-semibold max-sm:text-[36px] max-sm:leading-[45px]">
            {getStarted.title}
          </p>
          <p className="body16 max-w-[500px] text-center font-medium">
            {getStarted.description}
          </p>
          <div className="mt-5 flex items-center gap-x-4">
            <Button onClick={handleButton}>Create your DRep ID</Button>
            <Button variant="secondary">Learn More</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
